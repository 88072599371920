import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import franceMap from "../assets/img/francemap.jpeg";
import { Link } from "react-router-dom";

type Props = {};

const FranceComponent = (props: Props) => {
    return (
        <>
            <div>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>About France</Accordion.Header>
                        <Accordion.Body>
                            <div className="d-md-flex flex-sm-column flex-md-row gap-2">
                                <div className="col-md-6 mb-2">
                                    <img
                                        src={franceMap}
                                        className="mt-2 mx-4 img-fluid object-cover"
                                        style={{ width: "95%" }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Table striped bordered hover size="sm">
                                        <tbody>
                                            <tr>
                                                <td>Location</td>
                                                <td> France is a Schengen country in Europe. </td>
                                            </tr>
                                            <tr>
                                                <td>Capital</td>
                                                <td>Paris</td>
                                            </tr>
                                            <tr>
                                                <td>Currency</td>
                                                <td>Euro (1 EUR ≈ Rs 88.05 approx.)</td>
                                            </tr>
                                            <tr>
                                                <td>Neighbouring Countries</td>
                                                <td>Germany, Italy, Spain, Belgium, Luxembourg, Switzerland, Andorra, Monaco.</td>
                                            </tr>
                                            <tr>
                                                <td>Languages Spoken</td>
                                                <td>
                                                    French (While French is the primary language, many people, especially in urban areas and tourist spots, speak English.)
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Largest Cities in Denmark</td>
                                                <td>
                                                    Paris, Marseille, Lyon, Toulouse, Nice, Nantes, Strasbourg, Montpellier, Bordeaux, Lille.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Cost of Living</td>
                                                <td>
                                                    800 – 1200 EUR/ Month (living expenses vary depending on the city and lifestyle).
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Why Study in France?</Accordion.Header>
                        <Accordion.Body>
                            <div>

                                <ul>
                                    <li><b>No IELTS/TOEFL</b></li>
                                    <p>
                                        IELTS / TOEFL not mandatory for admission but having
                                        the same will have a positive impact on Visa outcome.
                                    </p>
                                    <li><b>Part time Jobs</b></li>
                                    <p>
                                        Students can work upto 20 hours/week. Typical part time earning is Euros 8‐10 per hour.
                                    </p>
                                    <li><b>Visa</b></li>
                                    <p>
                                        Every student is granted a specific Long Stay Visa for study purposes in France. However as the country falls within the Schengen area a student has the liberty to visit all other Schengen countries as a visitor for tourism purpose.
                                    </p>
                                </ul>

                            </div>
                        </Accordion.Body>
                    </Accordion.Item>



                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Popular Universities</Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <ol className="">
                                    <li>NEOMA Business School</li>
                                    <li>La Rochelle Business School, LaRochelle</li>
                                    <li>INSEEC Business School,Chambery</li>
                                    <li>France International Graduate School ‐ FIGS, Paris & Lyon</li>
                                    <li>College de Paris, Paris,France</li>
                                    <li>Grains Noble College</li>
                                    <li>IPAG</li>
                                    <li>ISC Paris</li>
                                    <li>EPITA</li>
                                    <li>ESC Clermont</li>
                                    <li>EICAR</li>
                                    <li>Schiller International University</li>
                                    <li>American Business School</li>
                                </ol>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="12">
                        <Accordion.Header>Popular Courses</Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <ol>
                                    <li>Tourism & Hospitality Management</li>
                                    <li>International Business</li>
                                    <li>Information Technology</li>
                                    <li>Luxury Brand Management</li>
                                    <li>MBA</li>
                                    <li>Finance & Accounting</li>
                                </ol>

                                <p >
                                    <b>Note:</b> <span className="text-danger">Approximate Tuition Fees per year for all courses lies between 7500 Euros to 15000 Euros.</span> 
                                </p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Intakes & Deadlines</Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <p className="fw-bold">Two Intakes:</p>
                                <Table striped bordered hover size="sm">
                                    <tbody>
                                        <tr>
                                            <td>Summer</td>
                                            <td>
                                                January/February
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Winter</td>
                                            <td>
                                                September/October
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <p className="fw-bold">General Deadlines for Applications:</p>
                                <Table striped bordered hover size="sm">
                                    <tbody>
                                        <tr>
                                            <td>Summer</td>
                                            <td>
                                                Up to 15thNovember
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Winter</td>
                                            <td>
                                                Up to 15thJuly
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <p>
                                    Some institutions accept applications beyond deadlines on a case to case basis.
                                </p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Documents for Application</Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <ol>
                                    <li>Duly filled Application form</li>
                                    <li>CV/Resume with contact details and email id of student</li>
                                    <li>Mark Sheets of 10th, 12th and Graduation</li>
                                    <li>Degree Certificate</li>
                                    <li>Two Letters of Reference</li>
                                    <li>Statement of Purpose</li>
                                    <li>Copy of Passport first and last pages</li>
                                    <li>Application fees payment receipt/credit card details (In case there is an application fees)</li>
                                    <li>Passport sizephotograph</li>
                                    <li>Skype id of student for taking skype interview by Institution.</li>
                                </ol>
                                <p>All documents are required in soft copies for making an application.</p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                        <Accordion.Header>Application Requirements</Accordion.Header>
                        <Accordion.Body>
                            <h5 className="text-primary">Please Note:</h5>

                            <ul>
                                <li>Provide updated mobile number, email id and Skype ID of the student as the same will be
                                    required for interview by institution.</li>
                                <li>Intimate the student that he/she will be interviewed by the institution representative and
                                    prepare the student to face it to reduce the chances of rejection.</li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="6">
                        <Accordion.Header>Application Process</Accordion.Header>
                        <Accordion.Body>
                            <h5 className="text-primary">Steps to follow</h5>
                            <ul>
                                <li>Application Submitted</li>
                                <li>Interview schedule will be sent by Instituition.</li>
                                <li>Acceptance or Rejection on the basis of Interview</li>
                                <li>Tuition fee to be deposited to get Final Aacceptance Letter</li>
                                <li>Campus France Procedure (Documents will be uploaded on the Campus France Portal)</li>
                                <li>Interview schedule with Campus France.</li>
                                <li>NOC Received after successful interview.</li>
                                <li>VFS Appointment taken for File Submission</li>
                                <li>File Submitted with VFS.</li>
                                <li>Visa decision awaited.</li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="7">
                        <Accordion.Header>Sample Questions for Interview</Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <h5>Here are some sample questions that are asked in Telephonic/Skype interview by institution:‐</h5>
                                <ol>
                                    <li>What is your name?</li>
                                    <li>What are you doing?</li>
                                    <li>What is your education qualification?</li>
                                    <li>Why you wantto go to France?</li>
                                    <li>Where in France you want to go?</li>
                                    <li>Where is your school located in France?</li>
                                    <li>Which institute or school or college you are going to study?</li>
                                    <li>Which course are you going to study?</li>
                                    <li>Why this school?</li>
                                    <li>What are the subjects?</li>
                                    <li>How will it help you in your career?</li>
                                    <li>How did you find school name? How did you come to know about institute?</li>
                                    <li>What is your father doing?</li>
                                    <li>When is your course going to start?</li>
                                    <li>Why France only?</li>
                                    <li>Your course is available in India, why do you wantto pursue the same at our
                                        institution?</li>
                                    <li>What are your long term goals after completion of course?</li>
                                    <li>Who is sponsoring your studies?</li>
                                    <li>What is your sponsor's income?</li>
                                    <li>Have you applied to any other institution?</li>
                                    <li> Are you working some where at present?</li>
                                </ol>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="8">
                        <Accordion.Header>
                            France VISA Procedure
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <div className="py-2">
                                    <ul>
                                        <li><b>STEP 1 – Register online</b></li>
                                        <p>
                                            Register online on <Link target="_blank" to='https://www.inde.campusfrance.org/'>inde.campusfrance.org</Link> Once you
                                            have registered online and filled the “Campus France form”
                                            along with uploading of documents, please email the file
                                            number (Example IN123456) to the nearest Campus France
                                            office for verification of the entries and confirmation.
                                        </p>
                                        <li><b>STEP 2 – Appointment with a Campus France EducationAdvisor</b></li>
                                        <p>
                                            Request an appointment through email from the Campus
                                            France office for an academic interview with the Education
                                            Advisor for which the student will have to appear in person
                                            and furnish all mandatory documents (originals + one set
                                            of photocopies). An NOC will be issued to the student after
                                            the successful completion of the interview.
                                        </p>
                                        <li><b>STEP 3 – Appointment at VFS France</b></li>
                                        <p>
                                            As soon as an appointment for the academic
                                            interview from the Campus France office is done the
                                            student may book an appointment online or
                                            telephonically for the documents submission at VFS
                                            France office for a date after the academic interview
                                            (generally a gap of 24 hours is suggested between the
                                            two interviews).
                                        </p>
                                    </ul>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="9">
                        <Accordion.Header>
                            VISA Fees andPayment
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <div className="gap-y-2">
                                    <h6>FEE PAYMENT PROCEDURE</h6>
                                    <ol>
                                        <li>Campus France fees of INR 15,500 need to be paid
                                            online after submission of application online.</li>
                                        <li>Submit the payment acknowledgment to the Campus
                                            France office on the day ofthe academic interview.</li>
                                        <li>There is a separate visa fee to be paid in cash at VFS France.
                                            VISA fees is EURO 99 (Rs . 7920 approx ).</li>
                                    </ol>

                                    <p><b>Note:</b> The duration of the entire process varies from two weeks
                                        to a month depending on the time of year. Applications earlier
                                        than three months before the scheduled date of departure will
                                        not be accepted.</p>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="10">
                        <Accordion.Header>Documents required for France Student Visa</Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <ul>
                                    <li>Long stay application form duly filled andsigned</li>
                                    <li>Acceptance/Admission letter from a French Academic Institution</li>
                                    <li>Academic records – Mark Sheets + Passing Certificates/Degree or Diploma Certificates
                                        (“10+2” onwards till last degree obtained)</li>
                                    <li>Language Test Certifications (IELTS / TOEFL) if required by institution in France</li>
                                    <li>Curriculum Vitae (CV)</li>
                                    <li>Experience Letter (if applicable)</li>
                                    <li>Any other documents that may have been uploaded on the Campus France online form</li>
                                    <li>Two passport size photographs of the past 3 months meeting set standards – one must be
                                        attached to the application form (Size 3.5 cm x 4.5 cm, with clear background)</li>
                                    <li>Passport</li>
                                    <li>One copy of the passport mentioning personal identity (1st Page) and validity extension
                                        (Last Page) information “note on remarks” page and all the pages which contain Schengen
                                        visas if any.</li>
                                    <li>Old passports: If you own one or more former passports, you must submit them.</li>
                                    <li>A print out of Campus France ID number generated for student.</li>
                                    <li>Cover letter explaining the study project and motivation, including short curriculum
                                        vitae.</li>
                                    <li>Proof of Funds.</li>
                                    <li>Proof of medical insurance.</li>
                                    <li>A copy of blocked Airticket.</li>
                                    <li>Visa and Residence Permit Application Forms.</li>
                                    <li>Accommodation arrangement letter from the institution. (Please ensure that
                                        accommodation is preferably booked for the entire duration of course else for atleast 3
                                        months).</li>
                                    <li>Past three years IT Returns of Self/Blood Relative (Sponsor) and past three months
                                        Bank statement of Self/Blood Relative(Sponsor).</li>

                                </ul>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="11">
                        <Accordion.Header>
                            Proof of Resources
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <ul>
                                    <li>Amount of Funds to be shown:</li>
                                    <p>
                                        Liquid assets to be shown is the sum total of the amount of tuition fees + a minimum of
                                        Euros 700 per month multiplied by the number of months of stay (living expenses). On an
                                        average around Euros 8,500 are required for living expenses per year. In case the course
                                        duration is for more than 12 months then living expenses are to be shown for a 12 month
                                        period only.
                                    </p>
                                    <li>
                                        Acceptable Funds:
                                        <ul>
                                            <li>Balance in Savings Account(Which ideally should be 3 months old).</li>
                                            <li>Fixed Deposit (Amaximum of 50% oftotalfunds can be shown through FD).</li>
                                            <li>Education Loan.</li>
                                        </ul>
                                    </li>
                                </ul>
                                <p><b>Note:</b> 100% funds can be shown through direct blood relatives.There should not be more
                                    than 2 sponsors.</p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
            </div>
        </>
    );
};

export default FranceComponent;
